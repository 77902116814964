@import '/src/assets/css/variabili.scss';

.pageTitle {
    color: $arancio;
    margin-top: 20px;
    text-align: center;

    span {
        font-weight: bold !important;
        color: $blue !important;
    }
}

.btnBack {
    color: white;
    padding: 10px;

    &:hover {
        color: white !important;
    }
}



.divTop {
    border-radius: 15px;
    color: #ffffff !important;
    background-color: $blue; 
    padding: 5px 0px 5px 15px;
    margin-top: 5px;
    text-shadow: none;
}


.dettaglio {
    font-size: 120%;
    text-align: left;
    background-color: #003a78;
    color: white;
    padding: 25px;
    margin-top: 5px;
    border-radius: 15px;
}