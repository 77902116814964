@import "./variabili.scss";

html,
body {
    max-width: 100%;
    overflow-x: hidden;
    color: $color-primary;
}
body {
    background-color: transparent;
    color: black;
}

.btn {
    color: white;
}

.btn-custom {
    background-color: $color-secondary;
    border-color: $color-secondary;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active {
    background-color: $color-primary;
    border-color: $color-primary;
    color: white;
}

.btn-custom.disabled:hover,
.btn-custom.disabled:focus,
.btn-custom.disabled:active,
.btn-custom.disabled.active,
.btn-custom[disabled]:hover,
.btn-custom[disabled]:focus,
.btn-custom[disabled]:active,
.btn-custom[disabled].active,
fieldset[disabled] .btn-custom:hover,
fieldset[disabled] .btn-custom:focus,
fieldset[disabled] .btn-custom:active,
fieldset[disabled] .btn-custom.active {
    background-color: $color-primary;
    border-color: $color-primary;
}
