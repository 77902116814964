@import '/src/assets/css/variabili.scss';

.title {
    text-align:center;
    color: $arancio;
}


.listPeopleChoice{
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.btnRow{
    &:hover{
        text-decoration: none !important;
    }
}

.attributo {
    border-radius: 15px;
    color: #ffffff !important;
    background-color: $blue;
    padding: 5px 0px 5px 15px;
    margin-top: 5px;
    text-shadow: none;

    &:hover{
        background: rgba($blue, .9);
    }
}

.tbItem{
    width: 100%;

    td.tdInfo{
        cursor: pointer;
        vertical-align: middle;
        padding: 5px 0px;

        .iconStar{
            color: $arancio;
            font-size: 1.4em; 
            display: inline; 
            margin-top: 3px;
            margin-right: 5px;
        }
    }
    td.tdArrow{
        width: 3%;
        vertical-align: 'middle';
        text-align: 'center';
    }

}
