@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap");

$blue: #003a78;
$arancio: #ee7623;

$barSize: 10px;

.bodyClass {
    background-image: url(https://digivents.s3-eu-west-1.amazonaws.com/IASP_SITE/ebc295b2-dd64-42ec-b7e6-2b90727fd43e.jpg);
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center bottom;
    min-height: 100vh;

    font-family: "Montserrat", sans-serif;
    color:white;
}



.wrapperRanking {
    width: 100%;
    height: 100%;
  
    .lboardWrap {
        width: 100%;
        height: 100%;
        border-radius: 5px;
  
        .lboardItem {
            padding: 25px;
        }
  
        .lboardMem {
            display: flex;
            align-items: center;
            padding: 15px 0;
            // border-bottom: 1px solid #ffe773;
  
            &:first-child {
                padding-top: 0;
            }
  
            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
            }
  
            .Img {
                width: 80px;

                img{
                    display: block;
                    width: 100%;
                }
            }
  
            .nameBar {
                margin: 0 20px;
                width: calc(100% - 150px);
  
                h4 {
                    color: white;
  
                    span {
                        margin-right: 10px;
                    }
                }
            }
  
            .points {
                width: 100px;
                color: white;
            }
  
            .barWrap {
                width: 100%;
                height: $barSize;
                // background: #d5b000;
                margin-top: 5px;
                border-radius:$barSize;
                position: relative;
                overflow: hidden;
  
                .innerBar {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: $barSize;
                    background: $arancio;
                    border-radius: $barSize;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }
        }
    }
  }