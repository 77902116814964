.dvImages {
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    .elementImg {
        max-height: 250px;
        margin: 10px;
        cursor: pointer;
    }
}
